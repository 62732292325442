<template>
  <div class="search">
    <!-- Recherche textuel -->
    <div class="search-wrapper"
      v-click-outside="() => (displayFilters = false)">
      <div class="search-input">
        <input class="search-input-input"
          type="text"
          name="search"
          :placeholder="searchPlaceholder"
          v-model="searchValue"
          v-on:keydown="keyUp()"
          @keyup.enter="sendSearchQuery()"
          @focus="displayFilters = false" />
        <div class="search-input-btns">
          <Btn class="search-input-btn-reset"
            v-if="searchValue"
            size="sm"
            iconSize="xs"
            icon="times-circle"
            @click="resetSearchQuery()" />
          <Btn class="search-input-btn-submit"
            size="sm"
            iconSize="xs"
            icon="search"
            @click="sendSearchQuery()"
            :disabled="!searchValue" />
        </div>
      </div>
      <Btn v-if="hasFilters"
        class="search-filter-btn"
        text="Filtrer"
        color="white"
        icon="filter"
        size="sm"
        @click="displayFilters = !displayFilters" />

      <!-- Recherche textuelle suggestions -->
      <div class="search-suggestions"
        :class="{
          active: displaySuggestions,
        }"
        v-click-outside="() => (displaySuggestions = false)">
        <ul class="search-suggestions__body">
          <li v-for="item in suggestions.data"
            :key="item.id">
            <slot name="suggestions"
              :item="item"> </slot>
          </li>
        </ul>
        <div class="search-suggestions__footer"
          v-if="suggestions.meta">
          <div class="search-suggestions-counter">
            <template v-if="suggestions.meta.pagination.count > 0">
              Voir les
              <span>{{ suggestions.meta.pagination.count }}</span> résultats
            </template>
            <template v-else> Aucun résultat </template>
          </div>
          <div class="search-suggestions-actions"
            v-if="suggestions.meta.pagination.count > 0">
            <Btn icon="share"
              round
              color="white"
              @click="sendSearchQuery()" />
          </div>
        </div>
      </div>

      <!-- filtres -->
      <div class="search-filter"
        :class="{
          active: displayFilters,
        }">
        <div class="search-filter-body grid">
          <slot />
        </div>
        <div class="search-filter-footer">
          <Btn text="Annuler"
            @click="displayFilters = !displayFilters" />
          <Btn @click="sendSearchQuery()"
            text="Filtrer"
            color="primary" />
        </div>
      </div>
    </div>

    <!-- Filtres actif-->
    <div class="search-current-filters">
      <template v-if="activeFilters.length > 0">
        <Tag v-for="filter in activeFilters"
          :key="filter.index"
          :text="filter.label"
          color="gray">
          <Btn @click="updateFilters(filter.name, filter.index)"
            class="tag-btn"
            round
            grow
            icon="times-circle"
            iconSize="xs" />
        </Tag>
      </template>
    </div>
  </div>
</template>

<script>
import Btn from '@/components/base/Btn.vue'
import Tag from '@/components/base/Tag.vue'

export default {
  name: 'Search',
  components: {
    Btn,
    Tag,
  },
  props: {
    modelValue: Object,
    searchEndpoint: String,
    apiParams: Object,
    searchPlaceholder: {
      type: String,
      default: ' ',
    },
  },
  data() {
    return {
      displaySuggestions: false,
      displayFilters: false,
      searchValue: '',
      filtersValues: '',
      activeFilters: [],
      apiQueryString: null,
      suggestions: [],
      hasFilters: false,
      forcedFilters: null,
      sort: null,
    }
  },
  mounted() {
    if (this.apiParams?.filters) {
      this.forcedFilters = this.apiParams.filters
    }
    if (this.apiParams?.sort) {
      this.sort = this.apiParams.sort
    }

    this.decodeUrlQueryString()

    if (Object.keys(this.modelValue.filters).length > 0) {
      this.hasFilters = true
    }

    this.emitter.on('list-update-active-filters', this.updateActiveFilters)
  },
  methods: {
    // Send seach query to parent
    sendSearchQuery() {
      // build query string for API
      this.getApiQueryString()

      // push filters and search to query params
      this.$router.push({ query: this.encodeUrlQueryString() })

      // Hide suggestion and filters
      this.displaySuggestions = false

      // Emit search query to list
      this.emitter.emit('list-update-params', { filters: this.apiQueryString })

      this.updateActiveFilters()

      // Close filters panel
      this.displayFilters = false
    },

    // decode active filtres & search from query params
    decodeUrlQueryString() {
      // Decode search value
      if (this.$route.query.search && this.$route.query.search !== '') {
        this.searchValue = this.$route.query.search
      }

      // decode filter
      if (this.$route.query.filters && this.$route.query.filters !== '') {
        const modelValueClone = this.modelValue
        const filtersArray = this.$route.query.filters.split(';')

        filtersArray.forEach((rawFilter) => {
          const filter = rawFilter.split(':')
          const filterValues = filter[1].split(',')
          const filterName = filter[0]
          const date = new Date().toLocaleString().split(',')
          date[0] = date[0].split('/').reverse().join('-')

          if (modelValueClone.filters[filterName]) {
            if (
              modelValueClone.filters[filterName].type === 'gte'
              && filterValues[0] === 'true'
            ) {
              modelValueClone.filters[filterName].values = [date[0]]
            }

            if (modelValueClone.filters[filterName].type === 'boolean') {
              const [value] = filterValues
              modelValueClone.filters[filterName].values = value
            }
            if (
              modelValueClone.filters[filterName].type === 'boolean'
              && filterValues[0] === 'false'
            ) {
              modelValueClone.filters[filterName].values = [false]
            }

            if (
              modelValueClone.filters[filterName].type === 'gte'
              && filterValues[0] === 'true'
            ) {
              modelValueClone.filters[filterName].values = [date[0]]
            }

            if (
              modelValueClone.filters[filterName].type === 'object'
              || modelValueClone.filters[filterName].type === 'relation'
            ) {
              const filtersObject = []
              filterValues.forEach((valueString) => {
                const valueArray = valueString.split('|')
                filtersObject.push({
                  key: valueArray[0],
                  value: valueArray[1],
                })
              })
              modelValueClone.filters[filterName].values = filtersObject
            }
          }
        })
      }
      if (
        (this.$route.query.search && this.$route.query.search !== '')
        || (this.$route.query.filters && this.$route.query.filters !== '')
      ) {
        this.$nextTick(() => {
          this.sendSearchQuery()
        })
      }
    },
    // encode filter & search for url
    encodeUrlQueryString() {
      const params = {}
      const date = new Date().toLocaleString().split(',')
      date[0] = date[0].split('/').reverse().join('-')

      // encode search values
      if (this.searchValue !== '') {
        params.search = this.searchValue
      }

      // encode filters
      const filters = []
      Object.entries(this.modelValue.filters).forEach(
        ([filterName, filter]) => {
          if (filter.values !== null) {
            if (filter.type === 'object' || filter.type === 'relation') {
              const filtersValues = []
              filter.values.forEach((item) => {
                filtersValues.push(`${item.key}|${item.value}`)
              })
              if (filtersValues.length > 0) {
                filters.push(`${filterName}:${filtersValues.join(',')}`)
              }
            }

            if (filter.type === 'boolean') {
              if (filter.values === true || filter.values === 'true') {
                filters.push(`${filterName}:true`)
              }
              if (filter.values === false || filter.values === 'false') {
                filters.push(`${filterName}:false`)
              }
            }

            if (filter.type === 'gte' && filter.values[0] === true) {
              filters.push(`${filterName}:${date[0]}`)
            }
            if (filter.type === 'boolean' && filter.values[0] === false) {
              filters.push(`${filterName}:false`)
            }
            if (filter.type === 'gte' && filter.values[0] === true) {
              filters.push(`${filterName}:${date[0]}`)
            }
          }
        },
      )

      if (filters.length > 0) {
        params.filters = filters.join(';')
      }

      return params
    },
    // encode filter for API
    getApiQueryString() {
      const searchQuery = []
      const filterQuery = []
      this.apiQueryString = null
      const date = new Date().toLocaleString().split(',')
      date[0] = date[0].split('/').reverse().join('-')

      // search all word in all attributs
      const words = this.searchValue.split(' ')
      words.forEach((word) => {
        if (word !== '') {
          this.modelValue.attributs.forEach((item) => {
            searchQuery.push(`${item}:ilk(%${word}%)`)
          })
        }
      })
      if (searchQuery.length > 0) {
        this.apiQueryString = searchQuery.join('|u|')
      }

      Object.entries(this.modelValue.filters).forEach(([item, filter]) => {
        // eslint-disable-next-line no-param-reassign
        item = item.replace('__', '.')

        if (filter.values) {
          if (filter.type === 'object') {
            const filterValues = []
            Object.entries(filter.values).forEach(([key]) => {
              filterValues.push(filter.values[key].key)
            })
            if (filterValues.length > 0) {
              filterQuery.push(
                `${item}.${filter.key}:in(${filterValues.join(',')})`,
              )
            }
          }

          if (filter.type === 'relation') {
            const filterValues = []
            Object.entries(filter.values).forEach(([key]) => {
              filterValues.push(filter.values[key].key)
            })
            if (filterValues.length > 0) {
              filterQuery.push(
                `${item}:in(${filterValues.join(',')})`,
              )
            }
          }

          if (filter.type === 'boolean') {
            if (filter.values === true || filter.values === 'true') {
              filterQuery.push(`${item}:ist())`)
            }
            if (filter.values === false || filter.values === 'false') {
              filterQuery.push(`${item}:isf())`)
            }
          }

          if (filter.type === 'gte' && filter.values[0] === true) {
            filterQuery.push(`${item}:gte(${date[0]}))`)
          }
          if (filter.type === 'boolean' && filter.values[0] === false) {
            filterQuery.push(`${item}:isf())`)
          }
          if (filter.type === 'gte' && filter.values[0] === true) {
            filterQuery.push(`${item}:gte(${date[0]}))`)
          }
        }
      })

      if (filterQuery.length > 0) {
        if (this.apiQueryString !== null) {
          this.apiQueryString = `[${this.apiQueryString}]|n|${filterQuery.join(
            '|n|',
          )}`
        } else {
          this.apiQueryString = filterQuery.join('|n|')
        }
      }

      if (this.forcedFilters !== null) {
        if (this.apiQueryString !== null) {
          this.apiQueryString = `[${this.apiQueryString}]|n|${this.forcedFilters}`
        } else {
          this.apiQueryString = this.forcedFilters
        }
      }
    },

    // Reset search query
    resetSearchQuery() {
      this.searchValue = ''
      this.apiQueryString = ''
      this.sendSearchQuery()
    },

    // update active filters
    updateActiveFilters() {
      this.activeFilters = []

      Object.entries(this.modelValue.filters).forEach(([item, filter]) => {
        if (filter.values && filter.values.length > 0) {
          if (filter.type === 'boolean') {
            let displayValue = ''

            if (filter.values === true || filter.values === 'true') {
              displayValue = 'oui'
            }

            if (filter.values === false || filter.values === 'false') {
              displayValue = 'non'
            }

            const localFilter = {
              name: item,
              label: `${filter.label} ${displayValue}`,
              index: 0,
            }
            this.activeFilters.push(localFilter)
          }

          if (filter.type === 'object' || filter.type === 'relation') {
            filter.values.forEach((obj, index) => {
              const localFilter = {
                name: item,
                label: `${obj.value}`,
                index,
              }
              this.activeFilters.push(localFilter)
            })
          }
        }
      })
      this.$router.push({ query: this.encodeUrlQueryString() })
    },

    // update v-model
    updateFilters(type, index) {
      const clone = this.modelValue
      if (Array.isArray(clone.filters[type].values)) {
        clone.filters[type].values.splice(index, 1)
      } else {
        clone.filters[type].values = null
      }
      this.$emit('update:modelValue', clone)
      this.$router.push({ query: this.encodeUrlQueryString() })

      this.sendSearchQuery()
    },

    // send search to api to get suggestions
    sendToApi() {
      if (this.searchValue && this.searchValue.length > 3) {
        this.getApiQueryString()
        if (this.searchEndpoint) {
          const params = {
            filters: this.apiQueryString,
            distinct: 1,
          }

          if (this.sort) {
            params.sort = this.sort
          }

          this.fetchService
            .get(this.searchEndpoint, params)
            .then(
              (response) => {
                this.suggestions = response
                if (!this.displayFilters) {
                  this.displaySuggestions = true
                }
              },
              (error) => {
                console.log(error)
              },
            )
        }
      }
    },

    keyUp() {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer)
        this.searchTimer = null
      }
      const that = this
      this.searchTimer = setTimeout(() => {
        that.sendToApi()
      }, 700)
    },
  },
}
</script>

<style lang="scss" scoped>
.search {
  @include v-padding($gutter-half);
  @include h-padding($gutter-half);
  background-color: $color-gray-lightestest;

  @include bp($breakpoint-sidebar-compact) {
    @include h-padding($gutter);
  }

  .search-wrapper {
    display: flex;
    justify-content: space-between;
    position: relative;
  }
}

.search-input {
  position: relative;
  flex-grow: 1;
  max-width: 60rem;

  .search-input-input {
    padding-right: (3.6rem * 2) + $gutter-half; // Space for buttons
    border-width: 2px;
    border-radius: $input-border-radius;

    &:focus {
      box-shadow: none;
    }

    &:not(:placeholder-shown) {
      color: $color-primary !important;
      border-color: $color-primary !important;

      &+.search-input-btns .search-input-btn-submit {
        color: $color-primary !important;
      }
    }
  }
}

.search-input-btns {
  position: absolute;
  right: 2px;
  top: 50%;
  transform: translateY(-50%);

  .search-input-btn-reset {
    color: $color-gray-light;
  }
}

.search-suggestions-counter {
  font-weight: $font-weight-semibold;
  font-size: $font-size-big;
  color: $color-gray-darky;

  span {
    color: $color-primary;
  }
}

.search-suggestions-actions {}

.search-filter-btn {
  margin-left: $gutter-quarter;
}

// SUGGESTIONS (auto-complete)

.search-suggestions,
.search-filter {
  position: absolute;
  bottom: -$gutter-quarter;
  width: 100%;
  background-color: white;
  border-radius: $border-radius-base;
  @include shadow(1);
  opacity: 0;
  transform: translateY(calc(100% - 6.4rem));
  z-index: +10;
  pointer-events: none; // Let events pass through
  transition: all 0.25s $ease-in-out;

  &.active {
    opacity: 1;
    transform: translateY(100%);
    pointer-events: initial;
  }
}

.search-suggestions {
  overflow: hidden;
  left: 0;
  max-width: 60rem; // Same as input
}

.search-suggestions__body {
  @include reset-list();

  ::v-deep(> li) {
    >a {
      display: block;
      padding: $gutter-quarter $gutter-half;
      border-bottom: 1px solid $color-gray-lighter;
      color: $color-gray-darky;

      @include hocus() {
        color: $color-gray-darkest;
        background-color: $color-gray-lightest;
      }
    }
  }
}

.search-suggestions__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $color-gray-lightest;
  padding: $gutter-quarter $gutter-half;
  min-height: 5.8rem;
}

// FILTERS

.search-filter {
  right: 0;
  max-width: 69.6rem;
  border: 1px solid $color-gray-light;
}

.search-filter-body {
  padding: $gutter $gutter-half;

  ::v-deep(.input-block) {
    margin-bottom: 0;
  }
}

.search-filter-footer {
  padding: $gutter-half;
  background-color: $color-gray-lightest;
  border-top: 1px solid $color-gray-light;
  display: flex;
  justify-content: flex-end;
  gap: $gutter-quarter;
}

.search-current-filters {
  display: flex;
  flex-wrap: wrap;
  gap: $gutter-eighth;
  margin-top: $gutter-half;

  &:empty {
    display: none;
  }
}
</style>
