<template>
  <div class="micro-parcel"
    :class="`${isWitness ? 'micro-parcel--margin' : ''} ${isEmpty ? 'micro-parcel--empty' : ''
      } ${!isEmpty && !isWitness && !isHidden && displayColors
        ? 'bg-micro-parcel-background-' + colorBackgroundIndex
        : ''
      } ${isHidden ? 'micro-parcel--hidden' : ''
      } ${isInList ? 'micro-parcel--in-list' : ''
      } ${checkIdInPath() ? 'micro-parcel--in-path' : ''
      } ${checkIdInPath() && path[findIndexPath()].moving
        ? 'micro-parcel--in-path-moving'
        : ''
      }`">
    <!-- Header -->
    <div class="micro-parcel-header"
      v-show="!isHidden">
      <div class="micro-parcel-computed-id">
        <span v-if="!isWitness && !isEmpty && displayComputedId">{{
          computedId
        }}</span>
      </div>
      <div class="micro-parcel-reference"
        v-if="isInlineWitness">
        <span>T</span>
      </div>
      <div class="micro-parcel-spacer"></div>
      <div v-if="displayGeo"
        class="micro-parcel-coordinates">
        GEO {{ coordinates }}
      </div>
    </div>

    <!-- Body -->
    <div class="micro-parcel-body"
      v-if="!isEmpty && !isWitness && !isHidden && displayDesignation">
      <div class="micro-parcel-designation">{{ designation }}</div>
    </div>

    <!-- Footer -->
    <div class="micro-parcel-footer"
      v-if="!isEmpty && !isWitness && !isHidden">
      <div v-if="displayRepetition"
        class="micro-parcel-repetition">
        Rep {{ repetition }}
      </div>
      <div class="micro-parcel-spacer"></div>
      <div v-if="displayModalityNumber"
        class="micro-parcel-modality-number">
        Mod {{ modalityNumber }}
      </div>
    </div>

    <!-- Modality number "wave" background -->
    <div v-if="!isEmpty && !isWitness && !isHidden && displayRepetition && displayColors"
      class="micro-parcel-modality-number-bg"
      :class="`${'color-micro-parcel-repetition-' + colorRepetitionIndex}`">
      <SvgIcon name="micro-parcel-wave" />
    </div>

    <!-- Add and delete buttons -->
    <div class="micro-parcel-btn micro-parcel-btn--delete"
      v-if="isEditable && !isEmpty && !isWitness">
      <Btn color="default"
        round
        icon="times-circle"
        iconSize="sm"
        iconAfter
        @click="deleteModa()" />
    </div>
    <div class="micro-parcel-btn micro-parcel-btn--add"
      v-if="isEditable && isEmpty">
      <Btn color="default"
        round
        icon="plus"
        iconSize="sm"
        iconAfter
        @click="addModa()" />
    </div>
    <div class="micro-parcel-btn micro-parcel-btn--path"
      v-if="isEditablePath && !isEmpty && !isWitness">
      <!-- Ajout dans la pile -->
      <Btn v-if="!checkIdInPath()"
        :color="checkIdInPath() ? 'primary' : 'default'"
        round
        icon="plus"
        iconSize="sm"
        iconAfter
        :text="checkIdInPath() ? (findIndexPath() + 1).toString() : null"
        @click="handlePath()" />
      <!-- En déplacement -->
      <Btn v-else-if="checkIdInPath() && path[findIndexPath()].moving"
        color="accent"
        :text="(findIndexPath() + 1).toString()"
        @click="handlePath()"
        size="lg" />
      <!-- Dans la pile -->
      <Btn v-else
        color="primary"
        :text="(findIndexPath() + 1).toString()"
        @click="handlePath()"
        size="lg" />
    </div>
  </div>
</template>

<script>
import Btn from '@/components/base/Btn.vue'
import SvgIcon from '@/components/base/SvgIcon.vue'

export default {
  name: 'MicroParcel',

  components: {
    Btn,
    SvgIcon,
  },

  props: {
    microParcelData: {
      type: Object,
      required: true,
    },
    pathData: {
      type: Object,
      required: false,
    },
    displayGeo: {
      type: Boolean,
      default: true,
    },
    displayComputedId: {
      type: Boolean,
      default: true,
    },
    displayRepetition: {
      type: Boolean,
      default: true,
    },
    displayModalityNumber: {
      type: Boolean,
      default: true,
    },
    displayDesignation: {
      type: Boolean,
      default: true,
    },
    displayColors: {
      type: Boolean,
      default: true,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    isEditablePath: {
      type: Boolean,
      default: false,
    },
    isHidden: {
      type: Boolean,
      default: false,
    },
    isInList: {
      type: Boolean,
      default: false,
    },
    x: {},
    y: {},
  },
  data() {
    return {
      isInlineWitness: false,
      isWitness: false,
      designation: null,
      computedId: null,
      coordinates: null,
      repetition: null,
      modalityNumber: null,
      colorBackgroundIndex: null,
      colorRepetitionIndex: null,
      isEmpty: false,
      path: [],
    }
  },
  emits: ['add-moda', 'delete-moda', 'handle-path'],
  watch: {
    microParcelData() {
      this.loadData()
    },
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      if (this.microParcelData !== undefined && this.microParcelData !== null) {
        this.designation = this.microParcelData?.modalite?.designation
        this.computedId = this.microParcelData?.numero
        this.coordinates = this.microParcelData?.coordonnees
        this.repetition = this.microParcelData?.repetition
        this.modalityNumber = this.microParcelData?.modalite?.ordre
        this.colorBackgroundIndex = this.microParcelData?.modalite?.ordre % 35
        this.colorRepetitionIndex = this.microParcelData.repetition % 9
        this.isEmpty = this.microParcelData.modalite === null || this.microParcelData.ordre === null

        this.isInlineWitness = this.microParcelData?.modalite?.type?.uid === 'TEMOIN'
        this.isWitness = this.microParcelData?.type?.uid === 'TEMOIN'

        if (this.microParcelData?.type?.uid === 'TEMOIN') {
          this.isEmpty = false
          this.isInlineWitness = false
        }
      }
      if (this.pathData !== undefined && this.pathData !== null) {
        this.path = this.pathData
      }
    },
    addModa() {
      this.$emit('add-moda', this.microParcelData)
    },
    deleteModa() {
      this.$emit('delete-moda', this.microParcelData)
    },
    handlePath() {
      this.$emit('handle-path', this.microParcelData)
    },
    checkIdInPath() {
      return this.path.map((p) => p.id).includes(this.microParcelData.id)
    },
    findIndexPath() {
      return this.path
        .map((p) => p.id)
        .findIndex((p) => p === this.microParcelData.id)
    },
  },
}
</script>

<style lang="scss" scoped>
// MICRO-PARCEL

.micro-parcel {
  max-height: 10rem;
  position: relative;
  font-size: $font-size-small;
  line-height: $line-height-small;
  background-color: $color-gray-light;

  .micro-parcels--display-black-and-white & {
    outline: $gutter-eighth solid;
    background-color: transparent;

  }

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.micro-parcels--display-black-and-white .micro-parcel--hidden{
      outline: $gutter-eighth solid $color-gray-lightest;
    }

.micro-parcel-body {
  // flex-shrink: 1;
  overflow: hidden;
}

.micro-parcel-header,
.micro-parcel-footer {
  display: flex;
  justify-content: space-between;
  font-weight: $font-weight-bold;
}

.micro-parcel-spacer {
  flex-grow: 1;
}

.micro-parcel-designation,
.micro-parcel-computed-id,
.micro-parcel-computed-id,
.micro-parcel-coordinates,
.micro-parcel-repetition,
.micro-parcel-modality-number {
  padding: $gutter-eighth;
}

.micro-parcel-reference {
  position: relative;
  padding: 0.6rem;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    @include size($gutter-half);
    border-radius: 50%;
    background-color: $color-gray-darker;
  }

  >span {
    color: white;
    overflow: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    // z-index: -1;
  }
}

// Center
.micro-parcel-designation {
  overflow: hidden;
  text-align: center;
  font-size: 1.1rem;
}

// Top left (modality number + repetition)
.micro-parcel-computed-id {}

// Top left (témoin)
.micro-parcel-computed-id {}

// Top right
.micro-parcel-coordinates {}

// Bottom left
.micro-parcel-repetition {
  z-index: +1;
  color: white;

  .micro-parcels--display-black-and-white & {
    color: inherit;
  }
}

// Bottom right
.micro-parcel-modality-number {}

// Wave background
.micro-parcel-modality-number-bg {
  position: absolute;
  left: 0;
  bottom: 0;

  >.icon {
    display: block;
    width: 5.6rem * 1.1;
    height: 3.6rem * 1.1;
  }
}

// ADD AND DELETE BUTTONS

.micro-parcel-btn {
  position: absolute;
  transform: translate3d(50%, -50%, 0);

  &.micro-parcel-btn--add {
    top: 50%;
    right: 50%;
  }

  &.micro-parcel-btn--delete,
  &.micro-parcel-btn--path {
    top: 0;
    right: 0;
    z-index: +1;
  }

  >.btn {
    padding: 0.4rem;
    min-width: 2.8rem;
    min-height: 2.8rem;
    // border-radius: 50%;
  }

  > :not(&.micro-parcel-btn--path).btn {
    background-color: white;
    color: $color-gray-light;

    @include hocus() {
      background-color: white;
      color: $color-gray-dark;
    }
  }
}

// SPECIAL MICRO-PARCELS

.micro-parcel--margin {
  background-color: $color-gray-lighter;

  .micro-parcel-coordinates {
    color: $color-gray;
  }
}

.micro-parcel--empty {
  outline: $gutter-eighth dashed $color-gray-light !important;
  background-color: transparent;

  .micro-parcel-coordinates {
    color: $color-gray-light;
  }

}

.micro-parcel--in-list {
  cursor: pointer;
  min-height: 10rem;

  @include hocus() {
    outline: $gutter-eighth solid $color-primary-lighty;
  }

  .micro-parcel-header {
    display: none;
  }

  .micro-parcel-footer {
    order: -1;
    justify-content: space-around;
  }

  .micro-parcel-spacer {
    display: none;
  }

  .micro-parcel-designation {}
}

.micro-parcel--in-path {
  outline: $gutter-eighth solid $color-primary;
}

.micro-parcel--in-path-moving {
  outline: $gutter-eighth solid $color-accent;
}

.micro-parcel--hidden {
  background-color: $color-gray-lightest;
}
</style>
