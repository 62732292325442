<template>
  <div
    class="micro-parcels"
    v-if="microParcels.length > 0"
    v-dragscroll
    :class="{
      'micro-parcels--is-editable': isEditable,
      'micro-parcels--is-editable-path': isEditablePath,
      'micro-parcels--display-headers': displayHeaders,
      'micro-parcels--display-borders': displayBorders,
      'micro-parcels--display-black-and-white': !displayColors,
      'micro-parcels--border-top': borderTop,
      'micro-parcels--border-bottom': borderBottom,
      'micro-parcels--border-left': borderLeft,
      'micro-parcels--border-right': borderRight,
    }"
  >
    <div
      class="micro-parcels-wrapper"
      :style="`
        grid-template-columns:
          ${displayHeaders ? 'auto' : ''}
          ${displayBorders ? 'auto' : ''}
          repeat(${colNumber}, 12rem)
          ${displayBorders ? 'auto' : ''};
        grid-template-rows:
          ${displayHeaders ? 'auto' : ''}
          ${displayBorders ? 'auto' : ''}
          repeat(${lineNumber}, 10rem)
          ${displayBorders ? 'auto' : ''};
      `"
    >
      <template v-for="(y, yi) in lineNumberTotal" :key="yi">
        <template v-for="(x, xi) in colNumberTotal" :key="xi">
          <!-- Headers -->
          <div
            class="micro-parcels-header micro-parcels-header--void"
            v-if="
              displayHeaders &&
              ((xi < 1 + displayBorders * 1 && yi < 1 + displayBorders * 1) ||
                (xi === 0 && yi > lineNumberTotal - (displayBorders * 1 + 1)) ||
                (yi === 0 && xi > colNumberTotal - (displayBorders * 1 + 1)))
            "
          ></div>
          <div
            class="micro-parcels-header micro-parcels-header--line"
            v-else-if="
              displayHeaders &&
              xi === 0 &&
              yi > displayBorders * 1 &&
              yi < lineNumberTotal - displayBorders * 1
            "
          >
            Ligne {{ yi - displayBorders * 1 }}
          </div>
          <div
            class="micro-parcels-header micro-parcels-header--col"
            v-else-if="
              displayHeaders &&
              yi === 0 &&
              xi > displayBorders * 1 &&
              xi < colNumberTotal - displayBorders * 1
            "
          >
            Colonne {{ xi - displayBorders * 1 }}
          </div>

          <!-- Borders -->
          <div
            class="micro-parcels-border micro-parcels-border--void"
            v-else-if="
              displayBorders &&
              ((xi === displayHeaders * 1 && yi === displayHeaders * 1) ||
                (xi === colNumberTotal - 1 && yi === displayHeaders * 1) ||
                (xi === displayHeaders * 1 && yi === lineNumberTotal - 1) ||
                (xi === colNumberTotal - 1 && yi === lineNumberTotal - 1))
            "
          ></div>
          <div
            class="micro-parcels-border micro-parcels-border--left"
            :class="{
              'micro-parcels-border--last': yi === lineNumberTotal - 2,
            }"
            v-else-if="displayBorders && xi === displayHeaders * 1"
          ></div>
          <div
            class="micro-parcels-border micro-parcels-border--right"
            :class="{
              'micro-parcels-border--last': yi === lineNumberTotal - 2,
            }"
            v-else-if="displayBorders && xi === colNumberTotal - 1"
          ></div>
          <div
            class="micro-parcels-border micro-parcels-border--top"
            :class="{ 'micro-parcels-border--last': xi === colNumberTotal - 2 }"
            v-else-if="displayBorders && yi === displayHeaders * 1"
          ></div>
          <div
            class="micro-parcels-border micro-parcels-border--bottom"
            :class="{ 'micro-parcels-border--last': xi === colNumberTotal - 2 }"
            v-else-if="displayBorders && yi === lineNumberTotal - 1"
          ></div>
                   <!-- Micro-parcel -->
          <MicroParcel
            v-else
            :microParcelData="
              microParcels[yi - displayBorders * 1 - displayHeaders * 1][
                xi - displayBorders * 1 - displayHeaders * 1
              ]
            "
            :pathData="path"
            :displayGeo="displayGeo"
            :x="xi - displayBorders * 1 - displayHeaders * 1"
            :y="yi - displayBorders * 1 - displayHeaders * 1"
            :displayComputedId="displayComputedId"
            :displayRepetition="displayRepetition"
            :displayModalityNumber="displayModalityNumber"
            :displayDesignation="displayDesignation"
            :displayColors="displayColors"
            :isEditable="isEditable"
            :isEditablePath="isEditablePath"
            :isHidden="isHidden((yi - displayBorders * 1 - displayHeaders * 1),(xi - displayBorders * 1 - displayHeaders * 1))"
            @add-moda="addModa"
            @delete-moda="deleteModa"
            @handle-path="handlePath"
          />

        </template>
      </template>

      <div class="micro-parcels-btns micro-parcels-btns--top" v-show="displayBtn">
        <Btn
          color="default"
          round
          icon="plus"
          iconSize="sm"
          iconAfter
          title="Ajouter une ligne"
          @click="manageGrid(1, null, 'add',lineNumber + 1,colNumber + 1)"
        />
        <Btn
          color="default"
          round
          icon="minus"
          iconSize="sm"
          iconAfter
          title="Supprimer une ligne"
          v-if="lineNumber > 1"
          @click="manageGrid(1, null, 'delete',lineNumber,colNumber)"
        />
      </div>
      <div class="micro-parcels-btns micro-parcels-btns--bottom" v-show="displayBtn">
        <Btn
          color="default"
          round
          icon="plus"
          iconSize="sm"
          iconAfter
          title="Ajouter une ligne"
          @click="manageGrid(lineNumber + 1, null, 'add',lineNumber + 1,colNumber + 1)"
        />
        <Btn
          color="default"
          round
          icon="minus"
          iconSize="sm"
          iconAfter
          title="Supprimer une ligne"
          v-if="lineNumber > 1"
          @click="manageGrid(lineNumber, null, 'delete',lineNumber,colNumber)"
        />
      </div>
      <div class="micro-parcels-btns micro-parcels-btns--left" v-show="displayBtn">
        <Btn
          color="default"
          round
          icon="plus"
          iconSize="sm"
          iconAfter
          title="Ajouter une colonne"
          @click="manageGrid(null, 1, 'add',lineNumber + 1,colNumber + 1)"
        />
        <Btn
          color="default"
          round
          icon="minus"
          iconSize="sm"
          iconAfter
          title="Supprimer une colonne"
          v-if="colNumber > 1"
          @click="manageGrid(null, 1, 'delete',lineNumber,colNumber)"
        />
      </div>
      <div class="micro-parcels-btns micro-parcels-btns--right" v-show="displayBtn">
        <Btn
          color="default"
          round
          icon="plus"
          iconSize="sm"
          iconAfter
          title="Ajouter une colonne"
          @click="manageGrid(null, colNumber + 1, 'add',lineNumber + 1,colNumber + 1)"
        />
        <Btn
          color="default"
          round
          icon="minus"
          iconSize="sm"
          iconAfter
          title="Supprimer une colonne"
          v-if="colNumber > 1"
          @click="manageGrid(null, colNumber, 'delete',lineNumber,colNumber)"
        />
      </div>
    </div>
  </div>

  <teleport to="#info-text" v-if="isEditablePath">
    <div v-if="isEditablePath">
      <Btn
        class="info-text-toggle-btn"
        :text="showHelp ? 'Masquer l\'aide' : 'Afficher l\'aide'"
        icon="info-circle"
        @click="showHelp = !showHelp"
      />
      <template v-if="showHelp">
        <div class="info-text-body">
          <template v-if="path.length === 0">
            <p>Aucun chemin n'est défini. Vous pouvez&nbsp;:</p>
            <ul>
              <li><b>Ajouter une micro-parcelle</b> au chemin avec le bouton "+"</li>
            </ul>
          </template>
          <template v-else-if="path.some((p) => 'moving' in p)">
            <p>La micro-parcelle {{ path.findIndex((p) => p.moving) + 1 }}
              est sélectionnée. Vous pouvez&nbsp;:</p>
            <ul>
              <li><b>Déplacer cette étape</b> du chemin sur une autre micro-parcelle
              avec le bouton "+" ou le numéro d'une autre micro-parcelle</li>
              <li><b>Supprimer cette étape</b>
              du chemin en re-cliquant sur le numéro de la même parcelle</li>
              <li><b>Désélectionner cette micro-parcelle</b>
              grâce au bouton "Annuler la sélection"</li>
            </ul>
          </template>
          <template v-else-if="path.some((p) => !('moving' in p))">
            <p>Aucune micro-parcelle n'est sélectionnée. Vous pouvez&nbsp;:</p>
            <ul>
              <li><b>Ajouter une micro-parcelle</b> au chemin avec le bouton "+"</li>
              <li><b>Sélectionner une micro-parcelle du chemin</b> par son numéro</li>
            </ul>
          </template>
        </div>
      </template>
    </div>
  </teleport>
</template>

<script>
/*
eslint-disable
*/
import MicroParcel from '@/components/micro-parcels/MicroParcel.vue'
import Btn from '@/components/base/Btn.vue'

export default {
  name: 'MicroParcels',

  components: {
    MicroParcel,
    Btn,
  },

  props: {
    microParcelsData: {
      type: Array,
      required: true,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    pathData: {
      type: [Array, String],
    },
    isEditablePath: {
      type: Boolean,
      default: false,
    },
    displayHeaders: {
      type: Boolean,
      default: true,
    },
    displayBorders: {
      type: Boolean,
      default: true,
    },
    displayGeo: {
      type: Boolean,
      default: true,
    },
    displayComputedId: {
      type: Boolean,
      default: true,
    },
    displayRepetition: {
      type: Boolean,
      default: true,
    },
    displayModalityNumber: {
      type: Boolean,
      default: true,
    },
    displayDesignation: {
      type: Boolean,
      default: true,
    },
    displayColors: {
      type: Boolean,
      default: true,
    },
    displayBtn:{
      type: Boolean,
      default: true,
    },
    hiddenModalities:{
      type: [Array, String],
      default:null
    },
    canPanY: {
      type: Boolean,
      default: false,
    },
    startingPoint: {
      type: String,
      default: 'HG',
    },
    borders: {
      type: Array,
      default() {
        return []
      },
    },
  },

  computed: {
    lineNumberTotal() {
      const lineNumberHeaders = this.displayHeaders ? 1 : 0
      const lineNumberBorders = this.displayBorders ? 2 : 0
      return this.lineNumber + lineNumberHeaders + lineNumberBorders
    },
    colNumberTotal() {
      const colNumberHeaders = this.displayHeaders ? 1 : 0
      const colNumberBorders = this.displayBorders ? 2 : 0
      return this.colNumber + colNumberHeaders + colNumberBorders
    },
  },
  data() {
    return {
      microParcels: [],
      path: [],
      lastClickedInPath: null,
      lineNumber: 0,
      colNumber: 0,
      borderTop: false,
      borderBottom: false,
      borderLeft: false,
      borderRight: false,
      filteredMoving: null,
      showHelp: false,
    }
  },
  emits: ['add-moda', 'delete-moda', 'manage-grid', 'handle-path'],
  watch: {
    microParcelsData() {
      if (this.microParcelsData.length > 0) {
        this.microParcels = []
        this.formatData()
      }
    },
  },
  created() {
    this.formatData()

    if (typeof this.pathData === 'string') {
      const path = JSON.parse(this.pathData)
      if (path && path.length > 0) {
        this.path = path
      }
    } else {
      this.path = this.pathData
    }
  },
  methods: {
    formatData() {
      this.borderTop = !!this.borders.find((border) => border === 'HAUT')
      this.borderBottom = !!this.borders.find((border) => border === 'BAS')
      this.borderLeft = !!this.borders.find((border) => border === 'GAUCHE')
      this.borderRight = !!this.borders.find((border) => border === 'DROITE')

      if (this.microParcelsData.length > 0) {
        this.lineNumber = parseInt(this.microParcelsData[
          this.microParcelsData.length - 1
        ].x)
        this.colNumber = parseInt(this.microParcelsData[
          this.microParcelsData.length - 1
        ].y)

        // eslint-disable-next-line no-unused-vars
        let matrix  = Array(this.lineNumber).fill().map(()=>Array(this.colNumber).fill())

        this.microParcelsData.forEach((item) => {
          matrix[item.x - 1][item.y - 1] = item
        })

        if(this.startingPoint == 'HG'){
          this.microParcels = matrix
        }

        if(this.startingPoint == 'HD'){
          this.microParcels = this.verticalM(matrix)
        }

        if(this.startingPoint == 'BG'){
          this.microParcels = matrix.reverse()
        }

        if(this.startingPoint == 'BD'){
          this.microParcels = this.verticalM(matrix).reverse()
        }
      }
    },
    verticalM(matrix) {
      const arr = []
      matrix.forEach((item)=>{
      const temp= []
        for (let i = item.length; i > 0; i--) {
          temp.push(item[i-1])
        }
        arr.push(temp)
      })
      return arr
    },
    manageGrid(x, y, type,mx,my) {
      this.$emit('manage-grid', { x, y, type,mx,my })
    },
    addModa(microParcelData) {
      this.$emit('add-moda', microParcelData)
    },
    deleteModa(microParcelData) {
      this.$emit('delete-moda', microParcelData)
    },
    isHidden(x,y){
      if(this.hiddenModalities){
        let hiddenModalitiesArray = this.hiddenModalities.split(',')
        const micro =  this.microParcels[x][y]
        return (hiddenModalitiesArray.find(item => parseInt(item) === micro?.modalite?.id)) ? true : false;
      }
      return false
    },
    handlePath(microParcelData) {
      const index = this.path.map((p) => p.id).findIndex((p) => p === microParcelData.id)
      this.filteredMoving = this.path.find((p) => p.moving)

      /**
       * L'index est déjà dans la pile
       */
      if (index !== -1) {
        let swapping = false

        /**
         * Un index est déjà en déplacement
         * L'inversion n'a pas encore été faite
         * Le dernier cliqué est différent de celui qui est cliqué actuellement
         * Donc on fait une inversion
         */
        if (
          this.filteredMoving
          && !swapping
          && (this.lastClickedInPath && this.lastClickedInPath.id !== this.path[index].id)
        ) {
          this.microParcelSwapping(index)

          swapping = true
        }

        /**
         * La case est en déplacement
         * L'inversion n'est pas faite
         * Le dernier cliqué est le même que lui sélectionné actuellement
         * Donc on supprime l'entrée dans le path
         */
        if (
          this.path[index].moving
          && !swapping
          && (this.lastClickedInPath && this.lastClickedInPath.id === this.path[index].id)
        ) {
          this.microParcelDelete(index)
        }

        /**
         * L'index existe, mais n'est pas en mode déplacement
         * L'inversion n'est pas faite
         * Deux cas :
         * - Le dernier cliqué est le même que lui sélectionné actuellement,
         *   on le met en déplacement
         * - Ou s'il existe déjà dans la pile (ex : 4 entrées, sélectionné en position 1),
         *   on le met en déplacement
         */
        if (
          (this.path[index] && !('moving' in this.path[index]))
          && !swapping
          && (
            (this.lastClickedInPath && this.lastClickedInPath.id === this.path[index].id)
            || this.path.findIndex((p) => p.id === microParcelData.id) !== -1
          )
        ) {
          this.path[index].moving = true
        }
      } else if (this.filteredMoving) {
        /**
         * Si une case est en déplacement,
         * et qu'on clique sur une autre case qui n'est pas présente dans la pile
         * on déplace son index grâce à l'id de la micro-parcelle qui a été cliqué
         */
        this.microParcelMoveIndex(microParcelData)
      } else {
        /**
         * On le rajoute à la pile s'il n'est pas dans la pile
         */
        this.path.push({
          id: microParcelData.id,
          x: microParcelData.x,
          y: microParcelData.y,
          ordre: microParcelData?.modalite?.ordre,
        })
      }

      this.$nextTick(() => {
        this.lastClickedInPath = {
          id: microParcelData.id,
          x: microParcelData.x,
          y: microParcelData.y,
          ordre: microParcelData?.modalite?.ordre,
        }
        this.$emit('handle-path', this.path)
      })
    },
    microParcelSwapping(index) {
      const moving = this.getMicroParcelleIndexMoving()

      const temp = this.path[index]

      this.path[index] = {
        id: this.path[moving].id,
        x: this.path[moving].x,
        y: this.path[moving].y,
        ordre: this.path[moving].ordre,
      }
      this.path[moving] = {
        id: temp.id,
        x: temp.x,
        y: temp.y,
        ordre: temp.ordre,
      }

      delete this.path[moving].moving
      delete this.path[index].moving
    },
    microParcelDelete(index) {
      this.path.splice(this.path.findIndex((p) => p.id === this.path[index].id), 1)
    },
    microParcelMoveIndex(microParcelData) {
      const moving = this.getMicroParcelleIndexMoving()

      this.path.splice(moving, 1, {
        id: microParcelData.id,
        x: this.filteredMoving.x,
        y: this.filteredMoving.y,
        ordre: this.filteredMoving?.ordre,
      })
    },
    getMicroParcelleIndexMoving() {
      return this.path.findIndex((p) => p.id === this.filteredMoving?.id)
    },
  },
}
</script>

<style lang="scss" scoped>
// MICRO-PARCELS

.micro-parcels {
  display: flex;
  // overflow: hidden; // No scrollbars mode
  overflow: auto;
  cursor: grab;
  max-height: 100%; // For modal usage
}

.micro-parcels--is-editable {
  height: 100%;
}

.micro-parcels-wrapper {
  position: relative;
  display: grid;
  gap: $gutter-half;
  width: fit-content;
  margin: auto;
  padding: $gutter-eighth $gutter;

  .micro-parcels--is-editable & {
    padding: $gutter-half + $gutter-and-half;
  }
}

// ADD AND DELETE ROWS AND COLUMNS

.micro-parcels-btns {
  display: none;

  .micro-parcels--is-editable & {
    position: absolute;
    display: flex;
    gap: $gutter-quarter;
  }
}

.micro-parcels-btns--top {
  top: $gutter-quarter;
  left: 50%;
  transform: translateX(-50%);
}

.micro-parcels-btns--bottom {
  bottom: $gutter-quarter;
  left: 50%;
  transform: translateX(-50%);
}

.micro-parcels-btns--left {
  top: 50%;
  left: $gutter-quarter;
  transform: translateY(-50%);
  flex-direction: column;
}

.micro-parcels-btns--right {
  top: 50%;
  right: $gutter-quarter;
  transform: translateY(-50%);
  flex-direction: column;
}

// HEADERS AND BORDERS

.micro-parcels-header {
  font-size: $font-size-small;
  font-weight: $font-weight-bold;
  white-space: nowrap;
  text-align: center;
  align-self: center;
}

.micro-parcels-border {
}

.micro-parcels-border--top,
.micro-parcels-border--bottom,
.micro-parcels-border--left,
.micro-parcels-border--right {
  position: relative;
  min-height: $gutter-quarter;
  min-width: $gutter-quarter;

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    background-color: transparent;
    border-radius: $gutter-eighth;
  }

  &:not(.micro-parcels-border--last) {
    &::before {
      content: "";
      display: block;
      position: absolute;
      background-color: transparent;
    }
  }
}

.micro-parcels-border--top,
.micro-parcels-border--bottom {
  &::after {
    width: 100%;
    height: $gutter-quarter;
  }

  &:not(.micro-parcels-border--last) {
    &::before {
      top: 0;
      right: -$gutter * 3 / 4;
      width: $gutter;
      height: $gutter-quarter;
    }
  }
}

.micro-parcels-border--left,
.micro-parcels-border--right {
  &::after {
    width: $gutter-quarter;
    height: 100%;
  }

  &:not(.micro-parcels-border--last) {
    &::before {
      bottom: -$gutter * 3 / 4;
      left: 0;
      width: $gutter-quarter;
      height: $gutter;
    }
  }
}

// Show active borders

.micro-parcels--border-top {
  .micro-parcels-border--top {
    &::after,
    &::before {
      background-color: $color-gray-light;
    }
  }
}

.micro-parcels--border-bottom {
  .micro-parcels-border--bottom {
    &::after,
    &::before {
      background-color: $color-gray-light;
    }
  }
}

.micro-parcels--border-left {
  .micro-parcels-border--left {
    &::after,
    &::before {
      background-color: $color-gray-light;
    }
  }
}

.micro-parcels--border-right {
  .micro-parcels-border--right {
    &::after,
    &::before {
      background-color: $color-gray-light;
    }
  }
}

// Info text

.info-text-toggle-btn {
  position: absolute !important;
  top: - $input-height-base;
}

.info-text-body {
  border-top: 1px solid $color-gray-lightest;
  border-bottom: 1px solid $color-gray-lightest;
  padding-top: $gutter-quarter;
}
</style>
