<template>
  <form @submit.prevent="onSubmit()">
    <div class="view-header" v-if="pageTitle">
      <div class="view-header__header">
        <Pagination />
      </div>
      <div class="view-header__footer">
        <h1 class="page-title" v-html="pageTitle"></h1>
      </div>
    </div>
    <div class="view-body">
      <Section class="section section--list">
        <Search
          v-model="search"
          returnObject
          :searchEnpoint="list.api"
          searchPlaceholder="Rechercher un plan"
        >
          <template v-slot:suggestions="{ item }">
            {{ item.nom }}
          </template>

          <SelectExtended
            id="type"
            v-model="search.filters.type.values"
            multiple
            search
            :label="search.filters.type.label"
            optionKey="id"
            optionValue="designation"
            apiEndpoint="dictionnaire/type/dispositif"
          />
        </Search>

        <List
          :api="list.api"
          :header="list.headers"
          :items="list.col"
          :item-key="list.key"
        >
          <template v-slot:group-actions="{ selectedItems }">
            <Btn
              v-if="helperService.userHasPermission('essaiplan_remove')"
              text="Supprimer"
              icon="trash-alt"
              color="default"
              @click="openModal('deleteItems', selectedItems)"
            />
          </template>

          <template v-slot:type="{ item }">
            {{ item.type.designation }}
          </template>

          <template v-slot:actions="{ item }">
            <Btn
              v-if="helperService.userHasPermission('essaiplan_show')"
              icon="info-circle"
              round
              grow
              @click="openModal('viewPlan', item)"
            />
            <ToolDropdown>
              <MenuItem
                v-if="helperService.userHasPermission('essaiplan_remove')"
                :item-data="{
                  label: 'Supprimer',
                  iconName: 'trash-alt',
                }"
                @click="openModal('deleteItem', item)"
              />
              <MenuItem
                v-if="helperService.userHasPermission('log_list')"
                :item-data="{
                  label: 'Voir les logs',
                  route: {
                    name: 'ressourceLogs',
                    params: {
                      ressource: 'protocol',
                      rid: 'protocol',
                      ressourceid: item.id,
                      from: 'protocols',
                      title: `Voir les logs`,
                    },
                  },
                  iconName: 'clipboard-list',
                }"
              />
            </ToolDropdown>

          </template>
        </List>
      </Section>
    </div>
  </form>

  <!-- Modals -->
  <!-- Voir le plan -->
  <Modal
    title="Consultation du plan"
    :active="modal.viewPlan"
    @modal-close="modal.viewPlan = false"
    :data="modalData"
    noScroll
    size="lg"
  >
    <template v-slot:modal-body="{ data }">
      <MicroParcels
        v-if="data.disposition && data.disposition.length > 0"
        :microParcelsData="data.disposition.flat()"
        :startingPoint="data?.point_depart?.uid"
        :displayBorders="false"
      />
    </template>
    <template v-slot:modal-footer>
      <Btn text="Fermer" color="primary" @click="modal.viewPlan = false" />
    </template>
  </Modal>

  <!-- Modals deleteItem -->
  <Modal
    title="Supprimer un plan&nbsp;?"
    :active="modal.deleteItem"
    :data="modalData"
    @modal-close="modal.deleteItem = false"
  >
    <template v-slot:modal-body>
      <p>
        Voulez vous vraiment supprimer le plan &nbsp;?
      </p>
    </template>
    <template v-slot:modal-footer="{ data }">
      <Btn text="Annuler" @click="modal.deleteItem = false" />
      <Btn
        text="Supprimer"
        @click="deleteItem(data)"
        icon="trash-alt"
        color="primary"
      />
    </template>
  </Modal>
  <!-- Modals deleteItems -->
  <Modal
    title="Supprimer des éléments&nbsp;?"
    :active="modal.deleteItems"
    :data="modalData"
    @modal-close="modal.deleteItems = false"
  >
    <template v-slot:modal-body="{ data }">
      <p>
        Voulez vous vraiment supprimer {{ data.length }} plans &nbsp;?
      </p>
    </template>
    <template v-slot:modal-footer="{ data }">
      <Btn text="Annuler" @click="modal.deleteItems = false" />
      <Btn
        text="Supprimer"
        @click="deleteItems(data)"
        icon="trash-alt"
        color="primary"
      />
    </template>
  </Modal>
</template>

<script>
import Section from '@/components/layout/Section.vue'
import Btn from '@/components/base/Btn.vue'
import List from '@/components/list/List.vue'
import Pagination from '@/components/list/Pagination.vue'
import Search from '@/components/list/Search.vue'
import Modal from '@/components/layout/Modal.vue'
import ToolDropdown from '@/components/layout/ToolDropdown.vue'
import MenuItem from '@/components/layout/MenuItem.vue'
import MicroParcels from '@/components/micro-parcels/MicroParcels.vue'
import SelectExtended from '@/components/form/SelectExtended.vue'

export default {
  name: 'EssaisLibraryView',

  components: {
    SelectExtended,
    MicroParcels,
    MenuItem,
    ToolDropdown,
    Modal,
    Search,
    Pagination,
    List,
    Btn,
    Section,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    return {
      search: {
        attributs: ['nom'],
        filters: {
          type: {
            type: 'object',
            key: 'id',
            values: [],
            label: 'Type',
          },
        },
      },

      list: {
        api: {
          endpoint: 'essai/plan',
          params: {
            sort: 'id.ASC',
            page: null,
            limit: null,
            filters: null,
          },
        },
        headers: [
          { label: 'Nom du plan d\'essai', dataField: 'nom' },
          { label: 'Type de dispositif' },
          { label: 'Modalités' },
          { label: 'Répétitions' },
        ],
        col: ['nom', 'type', 'nb_modalites', 'nb_repetitions'],
        key: 'id',
      },

      modal: {
        deleteItem: false,
        viewPlan: false,
        deleteItems: false,
      },
      modalData: {},
    }
  },

  mounted() {},

  methods: {
    openModal(modal, data) {
      this.modal[modal] = true
      if (modal === 'viewPlan') {
        const microparcels = JSON.parse(data.disposition)
        // eslint-disable-next-line
        data.disposition = microparcels
      }
      this.modalData = data
    },

    async deleteItem(item) {
      this.emitter.emit('open-loader')

      this.fetchService.delete(`essai/plan/${item.id}`).then(
        () => {
          this.emitter.emit('alert', {
            type: 'success',
            content: 'Le plan a bien été supprimé.',
          })
        },
        (responseError) => {
          this.emitter.emit('alert', {
            type: 'error',
            content: responseError.data,
          })
        },
      )

      this.emitter.emit('list-refresh')
      this.emitter.emit('close-loader')
      this.modal.deleteItem = false
    },

    async deleteItems(itemsToDelete) {
      this.emitter.emit('open-loader')

      await itemsToDelete.forEach((item) => {
        this.fetchService.delete(`essai/plan/${item.id}`).then(
          () => {
            this.emitter.emit('alert', {
              type: 'success',
              content: 'Les plans ont bien été supprimés.',
            })
          },
          (responseError) => {
            this.emitter.emit('alert', {
              type: 'error',
              content: responseError.data,
            })
          },
        )
      })

      this.emitter.emit('list-refresh')
      this.emitter.emit('close-loader')
      this.modal.deleteItems = false
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
